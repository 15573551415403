import _isNaN from 'lodash/isNaN';
import _trim from 'lodash/trim';

/*
 *
 * Package: `@dbh/is-string-blank`.
 *
 */ /**
 * Returns `true` if the given value is a string and is not blank.
 * @param {string?} input .
 * @return {boolean} .
 */const isStringBlank=a=>""===_trim(a)||_isNaN(a);

export { isStringBlank as default };
